:root {
  --pico-background-color: #121212 !important;
  --pico-primary-color: rgb(163, 175, 22) !important;
  --pico-form-element-background-color: #1e1e1e !important;
  --pico-primary-background: rgb(163, 175, 22) !important;
  --pico-primary-hover-background: rgba(163, 175, 22, 0.8) !important;
  --pico-text-selection-color: rgba(163, 175, 22, 0.8);
  --pico-primary-underline: rgba(163, 175, 22, 0.8);
  --pico-primary-focus: rgba(163, 175, 22, 0.8);
  --pico-primary-border: rgba(163, 175, 22, 0.8);
  --pico-primary-hover: rgba(163, 175, 22, 0.8);
  --pico-primary-active: rgba(163, 175, 22, 0.8);
  --pico-primary-disabled: rgba(163, 175, 22, 0.8);
  --pico-primary-focus: rgba(163, 175, 22, 0.38) !important;
}

input {
  --pico-primary-focus: rgba(163, 175, 22, 0.38) !important;
}

body {
  font-family: "Myriad Pro", sans-serif;
  font-weight: 100 !important;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("./graphic_assets/MyriadPro-SemiCn.otf") format("opentype");
}


.welcome-div {
  background: url("./graphic_assets/illusztracio1.svg") left center, url("./graphic_assets/illusztracio2.svg") right center, url("./graphic_assets/hatter.svg") no-repeat center center;
  text-align: center;
  background-size: 50% 50%, 50% 50%, cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.master-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px 10px;
}

@media (max-width: 768px) {
  .master-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px 10px;
  }
}

@media (max-width: 768px) {
  .welcome-div {
    background-size: 50% 50%, 50% 50%, cover !important;
    background-position: top center, bottom center, center center;
    height: unset;
  }
}


.greeting-div {
  background-image: linear-gradient( rgba(0, 0, 0, 0.5),  rgba(0, 0, 0, 0.5)  ), url("./MG-3728-Nagy.jpg") !important;
  text-align: center;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-top: 20vh;
  padding-bottom: 20vh;
  margin: auto;
}

.question-div {
  text-align: center;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  padding-top: 20vh;
  padding-bottom: 20vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.ender-div {
  text-align: center;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.ender-div > input {
  width: 70% !important;
  min-width: 150px;
  max-width: 500px;
}

.end-div {
  background: url("./graphic_assets/illusztracio1.svg") left center, url("./graphic_assets/illusztracio2.svg") right center, url("./graphic_assets/hatter.svg") no-repeat center center;
  text-align: center;
  background-size: 50% 50%, 50% 50%, cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}


@media (max-width: 768px) {
  .end-div {
    background-size: 50% 50%, 50% 50%, cover !important;
    background-position: top center, bottom center, center center;
    height: unset;
  }
}

.generic {
  font-size: 20px;
  text-align: justify;
  text-align-last: center;
  margin-right: 10vw;
  margin-left: 10vw;

}